/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: K Wang (https://sketchfab.com/15716533192)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/ef63b1bf46394bc09389367e82af0742
title: Shouji
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Iphone({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/iphone.gltf");
  return (
    <group ref={group} {...props} dispose={null} name="myphone">
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <mesh
          geometry={nodes.mesh_0.geometry}
          material={nodes.mesh_0.material}
        />
        <mesh
          geometry={nodes.mesh_1.geometry}
          material={nodes.mesh_1.material}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/iphone.gltf");
